import { useCallback } from "react";
import toast from "react-hot-toast";
import { z } from "zod";

import type { ShouldRevalidateFunction } from "@remix-run/react";
import { useFetcher } from "@remix-run/react";
import { defer, json } from "@remix-run/server-runtime";

import { validationError } from "~/components/forms/validationErrorResponse.server";

import { getProductImage } from "@commerce/shop/utils/image";
import { withZod } from "@remix-validated-form/with-zod";

import { SapAPIError } from "~/commerce-sap/.server/api/commerce-api";
import { EMPTY_BASKET_CODE } from "~/commerce-sap/shop/basket/empty.basket";
import ToastError from "~/components/toasts/toast-error";
import { useURL } from "~/contexts";
import {
  GIFT_ADD_TO_CART_FAILURE_MESSAGE,
  GIFT_CARD_ADD_ERROR_MESSAGE,
  MAX_ORDER_QUANTITY_EXCEEDED,
  PRODUCT_ADD_ERROR_MESSAGE,
  PRODUCT_ADD_TO_CART_FAILURE_MESSAGE,
} from "~/lib/constants";
import { useOnCompleted } from "~/lib/remix/fetcher";
import { useMediaScreen } from "~/lib/utils/screens";
import { invariant } from "~/lib/utils/utils";

const RESOURCE_URL = "/resources/basket";
export { RESOURCE_URL as BASKET_RESOURCE_URL };

export type BagProduct = {
  productId: string;
  algoliaQueryId?: string;
};

const BasketSchema = z.discriminatedUnion("action", [
  z.object({
    action: z.literal("addGiftcard"),
    productId: z.string().min(1),
    quantity: z.string().transform<number>(v => Number(v)),
    giftCardAmount: z.string().transform<number>(v => Number(v)),
    senderName: z.string().min(1),
    recipientName: z.string().min(1),
    recipientEmail: z.string().min(1),
    giftCardMessage: z.string().optional(),
    algoliaQueryId: z.string().optional(),
  }),
  z.object({
    action: z.literal("add"),
    productId: z.string().min(1),
    color: z.string().optional(),
    size: z.string().optional(),
    "leg-length": z.string().optional(),
    quantity: z.string().transform<number>(v => Number(v)),
    algoliaQueryId: z.string().optional(),
  }),
  z.object({
    action: z.literal("remove"),
    itemId: z.string().min(1),
  }),
  z.object({
    action: z.literal("update"),
    itemId: z.string().min(1),
    quantity: z.string().transform<number>(v => Number(v)),
  }),
  z.object({
    action: z.literal("addVoucher"),
    voucherId: z.string().min(1),
  }),
  z.object({
    action: z.literal("removeVoucher"),
    voucherId: z.string().min(1),
  }),
]);

export type GiftCardBagProduct = {
  productId: string;
  giftCardAmount: number;
  senderName: string;
  recipientName: string;
  recipientEmail: string;
  giftCardMessage: string;
  algoliaQueryId?: string;
};

type Actions = z.infer<typeof BasketSchema>;

export const BasketSchemaValidator = withZod(BasketSchema);
export const shouldRevalidate: ShouldRevalidateFunction = ({
  formMethod,
  formAction,
  currentUrl,
  nextUrl,
}) => {
  if (nextUrl?.pathname.includes("/logout")) return true;
  if (currentUrl?.pathname.includes("/logout")) return true;
  if (
    formMethod === "POST" &&
    (formAction?.includes("/checkout") ||
      formAction?.includes("/basket") ||
      formAction?.includes("/register") ||
      formAction?.includes("/login") ||
      formAction?.includes("/logout"))
  ) {
    return true;
  }

  return false;
};

export const loader = async ({ context, request }: LoaderArgs) => {
  const { shop } = context;
  const referer = request.headers.get("referer");
  const doStockCheck =
    referer?.includes("/cart") || referer?.includes("/checkout-delivery");
  const basketId = context.session.get("basketId");
  const myStoreInfo = context.session.get("myStoreInfo");

  const miniCartStockData =
    !basketId || !myStoreInfo?.store?.name || basketId === EMPTY_BASKET_CODE
      ? null
      : shop
          .getPointOfServiceStock(myStoreInfo.store.name)
          .then(value => value || null);
  const basket = await shop.getBasket(doStockCheck);
  invariant(basket, "Basket is required");
  return defer({ basket: basket, miniCartStockData });
};

export const action = async ({ request, context: { shop } }: ActionArgs) => {
  const validation = await BasketSchemaValidator.validate(
    await request.clone().formData(),
  );

  /* Handle any validation errros here */
  if (validation.error)
    return validationError(validation.error, validation.submittedData);

  const data = validation.data;

  try {
    switch (data.action) {
      case "addGiftcard":
      case "add": {
        const addToCartRequest = {
          algoliaQueryId: data.algoliaQueryId,
          product: { code: data.productId },
          quantity: data.quantity,
          giftCardData:
            data.action === "addGiftcard"
              ? {
                  giftCardAmount: data.giftCardAmount,
                  senderName: data.senderName,
                  recipientName: data.recipientName,
                  recipientEmail: data.recipientEmail,
                  giftCardMessage: data.giftCardMessage,
                }
              : undefined,
        };

        try {
          const [productData] = (await shop.getProducts([data.productId]))
            .products;
          const { basket, cartModificationResponse } =
            await shop.addItemToBasket(addToCartRequest);

          const productImage = getProductImage(productData) ?? [];
          let product = {
            name: "",
            price: 0,
            image: productImage,
            productData,
          };

          const item = basket?.entries?.find(
            p => p.product?.code === data.productId,
          );

          product = {
            ...product,
            name: item?.product?.name ?? "",
            price: item?.totalPrice?.value ?? 0,
          };

          return json({
            successfullyAdded: cartModificationResponse?.errorMessage
              ? false
              : true,
            basket,

            product,
            quantity: data.quantity,
            cartModification: cartModificationResponse,
          });
        } catch (e) {
          console.error("AddToCart error:", e);

          return json({
            failedToAdd: true,
          });
        }
      }
      case "remove": {
        const basket = await shop.removeItemFromBasket(data.itemId);
        return json({ successfullyRemoved: true, basket });
      }
      case "update": {
        const basket = await shop.updateItemInBasket(data.itemId, {
          quantity: data.quantity,
        });
        return json({ successfullyEdited: true, basket });
      }
      case "addVoucher": {
        try {
          const basket = await shop.addCouponToBasket(data.voucherId);
          return json({ successfullyAddedVoucher: true, basket });
        } catch (error) {
          if (error instanceof SapAPIError) {
            return json({
              successfullyAddedVoucher: false,
              cause: error.message,
            });
          }
          throw error;
        }
      }
      case "removeVoucher": {
        const basket = await shop.removeCouponFromBasket(data.voucherId);
        return json({ successfullyRemovedVoucher: true, basket });
      }
    }
    throw new Error("Unsupported action");
  } catch (e) {
    console.error(e);
    return validationError((e as Error).message, validation.submittedData);
  }
};

export const useBasketActions = () => {
  const fetcher = useFetcher<typeof action>();
  const url = useURL();
  const { isMobile } = useMediaScreen();
  const add = useCallback(
    (product: BagProduct, quantity = 1) => {
      fetcher.submit(
        {
          action: "add",
          ...product,
          quantity: quantity,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher],
  );

  const addGiftcard = useCallback(
    (product: GiftCardBagProduct, quantity = 1) => {
      fetcher.submit(
        {
          action: "addGiftcard",
          ...product,
          quantity: quantity,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher],
  );

  const remove = useCallback(
    (itemId: string) => {
      fetcher.submit(
        {
          action: "remove",
          itemId,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    [fetcher],
  );
  const update = useCallback(
    (itemId: string, quantity: number) => {
      fetcher.submit(
        {
          action: "update",
          itemId: itemId,
          quantity: quantity,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    [fetcher],
  );
  const addVoucher = useCallback(
    (voucherId: string) => {
      fetcher.submit(
        {
          action: "addVoucher",
          voucherId,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    [fetcher],
  );
  const removeVoucher = useCallback(
    (voucherId: string) => {
      fetcher.submit(
        {
          action: "removeVoucher",
          voucherId,
        } satisfies Actions,
        { action: url(RESOURCE_URL), method: "post" },
      );
    },
    [fetcher],
  );
  useOnCompleted(() => {
    if (fetcher.data && "failedToAdd" in fetcher.data) {
      toast.custom(
        t => (
          <ToastError
            t={t}
            message={"Failed to add to cart, please try again."}
          />
        ),
        {
          position: isMobile ? "top-center" : "top-right",
        },
      );
    } else if (fetcher.data && "cartModification" in fetcher.data) {
      if (
        fetcher.data?.cartModification.statusCode ===
        MAX_ORDER_QUANTITY_EXCEEDED
      ) {
        const maxQuantity = fetcher.data.product.productData.maxOrderQuantity;

        toast.custom(
          t => (
            <ToastError
              t={t}
              message={`You're only allowed to purchase ${maxQuantity} units of this product in a single order`}
            />
          ),
          {
            position: isMobile ? "top-center" : "top-right",
          },
        );
      } else if (
        fetcher.data.cartModification.errorMessage ===
          GIFT_CARD_ADD_ERROR_MESSAGE ||
        fetcher.data.cartModification.errorMessage === PRODUCT_ADD_ERROR_MESSAGE
      ) {
        const message =
          fetcher.data.cartModification.errorMessage ===
          GIFT_CARD_ADD_ERROR_MESSAGE
            ? GIFT_ADD_TO_CART_FAILURE_MESSAGE
            : PRODUCT_ADD_TO_CART_FAILURE_MESSAGE;
        toast.custom(t => <ToastError t={t} message={message} />, {
          position: isMobile ? "top-center" : "top-right",
        });
      }
    }
  }, fetcher);
  return {
    addGiftcard,
    add,
    update,
    remove,
    addVoucher,
    removeVoucher,
    fetcher,
  };
};
